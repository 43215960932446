<template>
  <div class="p-1">
  <!-- <b-row style="padding: 0px 1rem;margin-bottom: 2rem;">
    <b-col cols="8">Product Name</b-col>
    <b-col cols="2">Total</b-col>
    <b-col cols="2">Quantity</b-col>
    <b-col cols="8" class="mt-2">
      <customer-select />
    </b-col>
    <b-col cols="2" class="mt-2">
      <b-form-input />
    </b-col>
    <b-col cols="2" class="mt-2">
      <b-form-input />
    </b-col>
    <b-col cols="8" class="mt-1">
      <b-form-textarea />
      <b-button class="mt-2" variant="primary">Add Item</b-button>
    </b-col>
  </b-row> -->

  <table 
  class="ml-1">
    <tr>
      <th>Item</th>
      <th>Cost</th>
      <th>Qty</th>
      <th>Price</th>
    </tr>
    <tr >
      <td>
      <b-form-input />
      </td>
      <td>
      <b-form-input />
      </td>
      <td>
      <b-form-input />
      </td>
      <td>
      $34
      </td>
    </tr>
    <tr>
      <td>
      <b-form-input />
      </td>
    </tr>

  </table>
  <b-button variant="primary" class="ml-1 mt-1">Add Item</b-button>

  </div>  
</template>
<script>
import { BRow, BCol, BFormInput,BFormTextarea,BButton,BTable } from "bootstrap-vue";
import CustomerSelect from "./CustomerSelect.vue";

export default {
  components: {
    BRow,
    BButton,
    BTable,
    BFormTextarea,
    CustomerSelect,
    BFormInput,
    BCol,
  },
};
</script>
<style scoped>
table{
  border-spacing: 0 1rem;
  border-collapse:separate; 

}
  th:nth-child(1){
    width: 30rem;
  }
  th:nth-child(2){
    width: 15rem;
  }
  th:nth-child(3){
    width: 10rem;
  }
  td{
    padding-right: 10px;
  }
</style>
