
import { reactive, onMounted } from "@vue/composition-api";
import html2pdf from "html2pdf.js";
import moment from "moment";
import axios from "@axios";
import networkCall from 'axios'

export default function useInvoice() {

    const invoiceFields = [
        { key: 'productTitle', label: 'Product Title' },
        { key: 'productprice', label: 'price' },
        { key: 'quantity' },
    ]

    const invoiceListFields = [
        { key: 'invoiceNumber' },
        { key: 'invoiceToName', label: 'customer Name' },
        { key: 'invoiceSubTotal',label:'Invoice Total' },
        { key: 'issueDate' },
        // {key:'balance'},
        // {key:'actions'},
    ]

    const listItem = [
        {
            invoiceNumber: '#E123A',
            customerName: 'Adeel',
            total: 500,
            issuedDate: moment().format('DD MMMM YYYY'),
            balance: 200
        },
        {
            invoiceNumber: '#E124A',
            customerName: 'Adeel',
            total: 500,
            issuedDate: moment().format('DD MMMM YYYY'),
            balance: 200
        }
    ]

    const items = [
        {
            productName: 'Book 1 ',
            total: 34,
            quantity: 1
        },
        {
            productName: 'Book 2 ',
            total: 100,
            quantity: 5
        }
    ]

    function getInvoiceDetails(guid) {
        state.loading = true
        axios.get(`admin/invoice/invoice/single-user?invoiceGuid=${guid}`).then(res => {
            state.invoiceDetails = res.data.data
            state.invoiceDetails.issueDate = res.data.data.issueDate ?  moment(res.data.data.issueDate).format('DD MMMM YYYY') : moment().format('DD MMMM YYYY')
            state.invoiceDetails.dueDate = res.data.data.dueDate ?  moment(res.data.data.dueDate).format('DD MMMM YYYY') : moment().format('DD MMMM YYYY')
            state.loading = false
        }).catch(()=>{
        state.loading = false
        })
    }

    function exportToPDF(id) {
        //console.log('clicked');
        networkCall({
            url: state.invoiceDetails.invoiceUrl,
            method: 'GET',
            responseType: 'blob',
        }).then(response => {
            //console.log('respojse ', response.data);
            //   const url = window.URL.createObjectURL(new Blob([response.data]))
            const  fileURL = window.URL.createObjectURL(new Blob([response.data]));
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'file.pdf');
            document.body.appendChild(fileLink);
            fileLink.click();
        })
        // html2pdf(document.getElementById(id), {
        //         //   margin: 1,
        //           filename: `invoice(${new Date()}).pdf`,
        //           image:{
        //             type:'jpg'
        //           },
        //           useCORS:true
        //       });
    }

    function fetchAllInvoices() {
        state.loading = true
        axios.get('admin/invoice/invoices/all').then(res => {
            state.invoices = res.data.data;
            state.invoices.forEach((i) => {
                i.issueDate = moment(i.issueDate).format('DD-MM-YY')
            })
            state.loading = false
            //console.log('state.invoices', state.invoices);
        }).catch(err => {
            state.loading = false
        })
    }

    onMounted(() => {
        fetchAllInvoices()
    })


    function toDataURL(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onload = function () {
                callback(reader.result);
            }
            //console.log('res', xhr.response);
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    toDataURL('https://cdn.niso.dev/8c1fb068-5aaf-4f37-9a88-a4debe1509ca/734a3c12-728c-41b2-96b6-c181e129effb.png', function (dataUrl) {
        //console.log('RESULT:', dataUrl)
        state.imageUrl = dataUrl
    })

    const state = reactive({
        selected: null,
        loading: false,
        invoiceDetails: null,
        imageUrl: '',
        invoices: [],
    })

    function handleCustomerSelection(item) {
        state.selected = item
    }

    return {
        invoiceFields,
        getInvoiceDetails,
        exportToPDF,
        handleCustomerSelection,
        listItem,
        state,
        invoiceListFields,
        items
    }
}

