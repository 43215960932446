<template>
  <b-row class="d-flex">
    <b-card-text :class="customClass"
      ><strong :style="isFieldValueStrong ? '' : 'font-weight: 100;'">{{
        fieldText
      }}</strong></b-card-text
    >
    <span
      ><strong :style="isTextStrong ? '' : 'font-weight: 100;'">{{
        fieldValue
      }}</strong></span
    >
  </b-row>
</template>
<script>
import { BRow, BCardText } from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCardText,
  },
  props: {
    customClass: {
      required: true,
    },
    fieldText: {
      required: true,
      type: String,
    },
    fieldValue: {
      required: true,
      type: String,
    },
    isTextStrong: {
      required: false,
      type: Boolean,
    },
    isFieldValueStrong: {
      required: false,
      type: Boolean,
    },
  },
  setup() {},
};
</script>